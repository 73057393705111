/* eslint-disable require-jsdoc */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-unsafe-negation */
/* eslint-disable no-global-assign */
const localeSelectors = {
    closeModal: '#close-modal',
    stayInCountry: '#stay-in-country',
    moreOptionsLink: '#more-options-link',
    localeModal: '.js-locale-modal',
    countryLinksContainer: '.country-links-container'
};

const attributes = {
    manuallyChosenDomain: {
        name: 'manuallyChosenDomain',
        value: 'true',
        lifetime: 365
    }
};

const cookieUtils = require('plugin_frontend_core/utils/cookie-util');

function handleCloseModal(event) {
    event.preventDefault();
    const modal = document.querySelector(localeSelectors.localeModal);
    if (modal) {
        modal.style.display = 'none';
        cookieUtils.setCookie(attributes.manuallyChosenDomain.name, attributes.manuallyChosenDomain.value, attributes.manuallyChosenDomain.lifetime);
    }
}

function handleMoreOptionsLinkClick(event) {
    event.preventDefault();
    const countryLinks = document.querySelector(localeSelectors.countryLinksContainer);
    const initialContent = document.querySelector('.initial-content-container');
    if (!countryLinks || !initialContent) {
        console.error('Required containers for more options not found.');
        return;
    }

    initialContent.style.display = 'none';
    countryLinks.style.display = 'block';
}

function init() {
    const modal = document.querySelector(localeSelectors.localeModal);
    if (!modal) {
        console.error("Locale modal element not found.");
        return;
    }

    const closeModalButtons = modal.querySelectorAll(`${localeSelectors.closeModal}, ${localeSelectors.stayInCountry}`);
    if (closeModalButtons.length === 0) {
        console.error("Close modal or stay in country buttons not found.");
        return;
    }
    closeModalButtons.forEach(button => {
        button.addEventListener('click', handleCloseModal);
    });

    const moreOptionsLink = modal.querySelector(localeSelectors.moreOptionsLink);
    if (!moreOptionsLink) {
        console.error("More options link not found.");
        return;
    }
    moreOptionsLink.addEventListener('click', handleMoreOptionsLinkClick);
}

module.exports = {
    init: init
};
